<template>
  <section v-if="information">
    <b-overlay :show="loading" rounded="sm" v-if="AddInviteDTO">

      <h5 class="mt-3">{{ $t("NAME") }}</h5>
      <div class="mt-1">
        <b-form-input
          trim
          required
          type="text"
          v-model="AddInviteDTO.name"
          name="name"
        ></b-form-input>
      </div>

      <h5 class="mt-3">{{ $t("TITLE") }}</h5>
      <div class="mt-1">
        <b-form-input
          trim
          required
          type="text"
          v-model="AddInviteDTO.title"
          name="title"
        ></b-form-input>
      </div>

      
      <h5 class="mt-3">{{ $t("DEPARTMENT") }}</h5>
      <div class="mt-1">
        <b-form-input
          trim
          required
          type="text"
          v-model="AddInviteDTO.department"
          name="department"
        ></b-form-input>
      </div>

      <h5 class="mt-3">{{ $t("ADMINLEVEL.TITLE") }}</h5>
      <div class="mt-1">
        <b-form-select
          v-model="AddInviteDTO.is_admin"
          :options="adminOptions"
        ></b-form-select>
      </div>

      <h5 class="mt-3">{{ $t("LANGUAGE.TITLE") }}</h5>
      <div class="mt-1">
        <b-form-select
          name="edit_invite_select_language"
          v-model="AddInviteDTO.language"
        >
          <b-form-select-option
            v-for="(value, key) in sefos_locales"
            :key="key"
            :value="key"
          >
            {{ $t("LANGUAGE." + key) }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <h5 class="mt-3">{{ $t("AUTH_METHODS.SELECT_METHOD") }}</h5>
      <div class="mt-1">

        <b-form-select
          class="mb-2"
          @input="changedAuth"
          v-model="selectedIndex"
          :options="options"
        ></b-form-select>


        <div v-if="AddInviteDTO.auth_type == 'se-eid'">
          <b-form-input trim
            v-if="hideIdentifier"
            type="password"
            value="HAS_VALUE"
            @click="showIdentifier"
          ></b-form-input>
          <Pnr
            @blur="blurPnr"
            v-if="!hideIdentifier"
            v-model="AddInviteDTO.data.pnr"
          ></Pnr>
        </div>

        <Phone
          @valid="setValidated"
          required
          v-model="AddInviteDTO.data.identifier"
          v-if="AddInviteDTO.auth_type == 'password-sms'"
        ></Phone>

        <Identifier
          @valid="setValidated"
          v-model="AddInviteDTO.data.identifier"
          :placeholder="$t('UNIQUE_IDENTIFIER')"
          v-if="InputIdentifier"
        ></Identifier>
      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button
        :disabled="updateDisabled"
        variant="primary"
        class="btn-fill"
        @click="updateInvite"
        >{{ $t("SAVE") }}</b-button
      >

      <b-button
        variant="danger"
        class="btn-fill float-right"
        @click="removeSubmit"
        >{{ $t("REMOVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Phone from "@/components/Input/Phone.vue";
import Identifier from "@/components/Input/Identifier.vue";
export default {
  components: { Pnr, Identifier, Phone },
  props: ["organisation", "information", "inviteId"],
  data() {
    return {
      hideIdentifier: false,
      selectedIndex: 0,
      selectedKey: 0,
      validated: true,
      loading: false,
      show: false,
      methods: [],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      options: [],
      update_identifier: "",
      AddInviteDTO: null,
    };
  },
  methods: {
    async Init(){
      await this.getMethods();
      this.AddInviteDTO = {
        id: this.inviteId,
        is_admin: this.information.is_admin,
        language: this.$i18n.locale,
        organisation_id: this.organisation.id,
        auth_type: this.information.auth_type,
        auth_method: this.information.auth_method,
        email: this.information.email,
        name: this.information.name,
        title: this.information.title,
        department: this.information.department,
        data: {
          identifier: this.information.data.identifier,
          password1: "",
          password2: "",
          pnr: this.information.data.pnr,
        },
      };
      if(this.AddInviteDTO.data.pnr == "HAS_VALUE")
      {
        this.update_identifier = "HAS_VALUE";
        this.hideIdentifier = true;
      }
      this.processMethods();
    },
    showIdentifier(){
      if(this.AddInviteDTO.data.pnr == "HAS_VALUE")
      {
        this.AddInviteDTO.data.pnr = "";
      }
      this.hideIdentifier = false;
    },
    blurPnr(){
      if(this.update_identifier == "HAS_VALUE" && (this.AddInviteDTO.data.pnr == ""))
      {
        this.AddInviteDTO.data.pnr = "HAS_VALUE";
        this.hideIdentifier = true;
      }
    },
    removeSubmit: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            let invites = [];
            invites.push(self.inviteId);
            self.loading = true;
            self.$http
              .post(self.user.hostname + "/invite/remove", {
                invites: invites,
              })
              .then(function () {
                self.$emit("removedInvite");
                self.$noty.info(self.$t("REMOVED"));
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    showSidebar() {
      this.getMethods();
    },
    changedAuth() {
      this.selectedKey++;
      this.valid = false;
      let method = this.methods[this.selectedIndex];
      this.AddInviteDTO.auth_type = method.auth_type;
      if (method.auth_type == "custom") {
        this.AddInviteDTO.auth_method = method.auth_method;
      }else{
        this.AddInviteDTO.auth_method = "";
      }
      if (this.AddInviteDTO.auth_type == "siths-card") {
        this.valid = true;
      }
      if (this.AddInviteDTO.auth_type == "sefos-certificate") {
        this.valid = true;
      }
      if (this.AddInviteDTO.auth_type == "custom") {
        this.valid = true;
      }
    },
    setValidated(value) {
      this.validated = value;
    },
    async updateInvite() {
      this.loading = true;
      try {
        await this.$http.post(this.user.hostname + "/invite/update", this.AddInviteDTO);
        this.loading = false;
        if(this.AddInviteDTO.data.pnr != ""){
          this.update_identifier = "HAS_VALUE";
        }
        this.$noty.info(this.$t("SAVED"));
      } catch {
        this.loading = false;
        console.log("Could not updateInvite");
      }   
    },
    changeValid(valid) {
      this.valid = valid;
    },
    async processMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        let name = "";
        if(method.auth_type == "custom")
        {
          if(this.AddInviteDTO.auth_type == "custom")
          {
            if(this.AddInviteDTO.auth_method == method.auth_method)
            {
              this.selectedIndex = ix;
            }
          }
          name = method.custom_name;
        }else{
          if(this.AddInviteDTO.auth_type == method.auth_type)
          {
            this.selectedIndex = ix;
          }
          name = this.$t("AUTH_METHODS." + method.auth_type)
        }
        this.options.push({
          value: ix,
          text: name
        });
        this.loadedMethods = true;
      }
    },
    async getMethods() {
      this.loading = true;
      try {
        let result = await this.$http.get(this.user.hostname + "/invite/methods/list");      
        this.methods = result.data;
        this.loading = false;
      } catch {
        this.loading = false;
        console.log("Could not getMethods");
      }   
    }
  },
  computed: {
    adminOptions: function () {
      if (this.user.information.is_admin == 2) {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
          { value: 2, text: this.$t("ADMINLEVEL.2") },
        ];
      } else {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
        ];
      }
    },
    updateDisabled: function () {
      if (this.validated == false) {
        return true;
      }
      if (this.AddInviteDTO.auth_type == "password-sms") {
        if (this.AddInviteDTO.data.identifier == "") {
          return true;
        }
      }
      if (this.AddInviteDTO.auth_type == "se-eid") {
        if (this.AddInviteDTO.data.pnr == "") {
          return true;
        }
      }
      if (this.AddInviteDTO.email == "") {
        return true;
      }
      if (this.AddInviteDTO.name == "") {
        return true;
      }
      return false;
    },
    InputIdentifier() {
      if(this.methods.length != 0)
      {
        if (this.AddInviteDTO.auth_type == "custom") {
          return this.methods[this.selectedIndex].needs_identifier == 1;
        }
        return this.AddInviteDTO.auth_type == "freja-org";
      }
      return false;
    },
  },
  watch: {
    /*"information.auth_method": function () {
      this.Init();
    },
    "information.auth_type": function () {
      this.Init();
    },*/
  },
  mounted() {
    console.log(this.information);
    this.Init();
  },
};
</script>
<style></style>
