var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _vm.AddInviteDTO
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("NAME"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          trim: "",
                          required: "",
                          type: "text",
                          name: "name",
                        },
                        model: {
                          value: _vm.AddInviteDTO.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddInviteDTO, "name", $$v)
                          },
                          expression: "AddInviteDTO.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("TITLE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          trim: "",
                          required: "",
                          type: "text",
                          name: "title",
                        },
                        model: {
                          value: _vm.AddInviteDTO.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddInviteDTO, "title", $$v)
                          },
                          expression: "AddInviteDTO.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("DEPARTMENT"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          trim: "",
                          required: "",
                          type: "text",
                          name: "department",
                        },
                        model: {
                          value: _vm.AddInviteDTO.department,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddInviteDTO, "department", $$v)
                          },
                          expression: "AddInviteDTO.department",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("ADMINLEVEL.TITLE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.adminOptions },
                        model: {
                          value: _vm.AddInviteDTO.is_admin,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddInviteDTO, "is_admin", $$v)
                          },
                          expression: "AddInviteDTO.is_admin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("LANGUAGE.TITLE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { name: "edit_invite_select_language" },
                          model: {
                            value: _vm.AddInviteDTO.language,
                            callback: function ($$v) {
                              _vm.$set(_vm.AddInviteDTO, "language", $$v)
                            },
                            expression: "AddInviteDTO.language",
                          },
                        },
                        _vm._l(_vm.sefos_locales, function (value, key) {
                          return _c(
                            "b-form-select-option",
                            { key: key, attrs: { value: key } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("LANGUAGE." + key)) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("AUTH_METHODS.SELECT_METHOD"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-select", {
                        staticClass: "mb-2",
                        attrs: { options: _vm.options },
                        on: { input: _vm.changedAuth },
                        model: {
                          value: _vm.selectedIndex,
                          callback: function ($$v) {
                            _vm.selectedIndex = $$v
                          },
                          expression: "selectedIndex",
                        },
                      }),
                      _vm.AddInviteDTO.auth_type == "se-eid"
                        ? _c(
                            "div",
                            [
                              _vm.hideIdentifier
                                ? _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      type: "password",
                                      value: "HAS_VALUE",
                                    },
                                    on: { click: _vm.showIdentifier },
                                  })
                                : _vm._e(),
                              !_vm.hideIdentifier
                                ? _c("Pnr", {
                                    on: { blur: _vm.blurPnr },
                                    model: {
                                      value: _vm.AddInviteDTO.data.pnr,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.AddInviteDTO.data,
                                          "pnr",
                                          $$v
                                        )
                                      },
                                      expression: "AddInviteDTO.data.pnr",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.AddInviteDTO.auth_type == "password-sms"
                        ? _c("Phone", {
                            attrs: { required: "" },
                            on: { valid: _vm.setValidated },
                            model: {
                              value: _vm.AddInviteDTO.data.identifier,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.AddInviteDTO.data,
                                  "identifier",
                                  $$v
                                )
                              },
                              expression: "AddInviteDTO.data.identifier",
                            },
                          })
                        : _vm._e(),
                      _vm.InputIdentifier
                        ? _c("Identifier", {
                            attrs: { placeholder: _vm.$t("UNIQUE_IDENTIFIER") },
                            on: { valid: _vm.setValidated },
                            model: {
                              value: _vm.AddInviteDTO.data.identifier,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.AddInviteDTO.data,
                                  "identifier",
                                  $$v
                                )
                              },
                              expression: "AddInviteDTO.data.identifier",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: {
                        disabled: _vm.updateDisabled,
                        variant: "primary",
                      },
                      on: { click: _vm.updateInvite },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill float-right",
                      attrs: { variant: "danger" },
                      on: { click: _vm.removeSubmit },
                    },
                    [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }