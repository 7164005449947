<template>
  <section v-if="inviteId != 0">
    <div v-if="information">
      <h3 class="pt-0 mt-0" v-if="1 == 0">
        <b-img
          class="profileImage"
          src="/img/default-avatar.png"
          :alt="information.name"
          rounded="circle"
        ></b-img>
        {{ information.name }}
      </h3>

      <InviteUpdate
        v-if="information"
        @updatedInvite="updatedInvite"
        @removedInvite="removedInvite"
        :inviteId="inviteId"
        :information="information"
        :organisation="organisation"
      ></InviteUpdate>
    </div>
  </section>
</template>
<script>
import InviteUpdate from "@/components/Invite/Update";
export default {
  props: ["inviteId", "organisation"],
  components: {
    InviteUpdate,
  },
  data() {
    return {
      information: null,
    };
  },
  methods: {
    updatedInvite: function () {
      this.$emit("updatedInvite");
    },
    removedInvite: function () {
      this.$emit("removedInvite");
    },
    getInformation: function () {
      let self = this;
      this.$http
        .get(this.user.hostname + "/invite/byid/" + this.inviteId)
        .then((response) => {
          self.information = response.data;
        })
        .catch(() => {});
    },
  },
  watch: {
    user_uuid: function (newVal, oldVal) {
      // watch it
      if (newVal != oldVal) {
        this.getInformation();
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.getInformation();
  },
};
</script>
<style></style>