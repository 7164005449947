var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c(
            "b-button",
            { staticClass: "no-border", on: { click: _vm.refreshList } },
            [_c("i", { staticClass: "fal fa-sync" })]
          ),
          _c(
            "AddInvite",
            {
              attrs: { organisation: _vm.organisation },
              on: { invitedResult: _vm.refreshList },
            },
            [
              _c(
                "b-btn",
                { staticClass: "inlineBtn", attrs: { variant: "dark" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("ORGANISATION.ADD_USER")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "label",
            { staticClass: "sr-only", attrs: { for: "search-users" } },
            [_vm._v(_vm._s(_vm.$t("SEARCH")))]
          ),
          _c("b-form-input", {
            staticClass: "searchText hidden-mobile",
            attrs: {
              trim: "",
              id: "search-users",
              placeholder: _vm.$t("SEARCH"),
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _c(
            "label",
            { staticClass: "sr-only", attrs: { for: "list-users-limit" } },
            [_vm._v(_vm._s(_vm.$t("LIMIT")))]
          ),
          _c(
            "b-form-select",
            {
              staticClass: "limit hidden-mobile",
              attrs: { id: "list-users-limit" },
              on: { change: _vm.getItems },
              model: {
                value: _vm.limit,
                callback: function ($$v) {
                  _vm.limit = $$v
                },
                expression: "limit",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: "10" } }, [
                _vm._v("10"),
              ]),
              _c("b-form-select-option", { attrs: { value: "25" } }, [
                _vm._v("25"),
              ]),
              _c("b-form-select-option", { attrs: { value: "45" } }, [
                _vm._v("45"),
              ]),
              _c("b-form-select-option", { attrs: { value: "100" } }, [
                _vm._v("100"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "hidden-not-mobile w-100 mt-2" },
            [
              _c(
                "b-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "search-users-mobile" },
                    },
                    [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                  ),
                  _c("b-form-input", {
                    staticClass: "ml-2 i-100 searchText",
                    attrs: {
                      trim: "",
                      id: "search-users-mobile",
                      placeholder: _vm.$t("SEARCH"),
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "list-users-limit-mobile" },
                    },
                    [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                  ),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "limit",
                      attrs: {
                        id: "list-users-limit-mobile",
                        name: "users_limit",
                      },
                      model: {
                        value: _vm.limit,
                        callback: function ($$v) {
                          _vm.limit = $$v
                        },
                        expression: "limit",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "10" } }, [
                        _vm._v("10"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "25" } }, [
                        _vm._v("25"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "45" } }, [
                        _vm._v("45"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "100" } }, [
                        _vm._v("100"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "filter-dropdown",
              attrs: {
                id: "dropdown-1",
                size: "sm",
                text: _vm.filterTextStatus,
                "toggle-class": _vm.filterClassStatus,
              },
            },
            [
              _c(
                "b-dropdown-form",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        switch: "",
                        size: "sm",
                        name: "check-button",
                        value: "0",
                      },
                      on: { change: _vm.getItems },
                      model: {
                        value: _vm.filter_status,
                        callback: function ($$v) {
                          _vm.filter_status = $$v
                        },
                        expression: "filter_status",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("FILTER.STATUS_ACCOUNTS.0")))]
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-form",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        switch: "",
                        size: "sm",
                        name: "check-button",
                        value: "1",
                      },
                      on: { change: _vm.getItems },
                      model: {
                        value: _vm.filter_status,
                        callback: function ($$v) {
                          _vm.filter_status = $$v
                        },
                        expression: "filter_status",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("FILTER.STATUS_ACCOUNTS.1")))]
                  ),
                ],
                1
              ),
              _vm.filter_status.length != 0
                ? _c("b-dropdown-divider")
                : _vm._e(),
              _vm.filter_status.length != 0
                ? _c(
                    "b-dropdown-item",
                    { on: { click: _vm.clearFilterStatus } },
                    [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR_SELECTED")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.showFilterClearAll
            ? _c(
                "b-button",
                {
                  staticClass: "no-border",
                  attrs: { size: "sm" },
                  on: { click: _vm.clearFilterAll },
                },
                [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("b-table", {
        key: _vm.usersKey,
        staticClass: "clickable mt-2",
        attrs: {
          small: "",
          busy: _vm.loading,
          bordered: "",
          items: _vm.items,
          hover: "",
          fields: _vm.fields,
        },
        on: { "row-clicked": _vm.clickedRow },
        scopedSlots: _vm._u([
          {
            key: "head(state)",
            fn: function () {
              return [
                _vm.items.length != 0
                  ? _c("b-form-checkbox", {
                      on: { change: _vm.addAll },
                      model: {
                        value: _vm.add_all,
                        callback: function ($$v) {
                          _vm.add_all = $$v
                        },
                        expression: "add_all",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                _c("b-img", {
                  staticClass: "profileImage",
                  attrs: {
                    src: "/img/default-avatar.png",
                    alt: data.item.name,
                    rounded: "circle",
                  },
                }),
                _vm._v("\n      " + _vm._s(data.item.name) + "\n      "),
                data.item.type == "invite"
                  ? _c("b-badge", { attrs: { variant: "info" } }, [
                      _vm._v(_vm._s(_vm.$t("STATUS_INVITED"))),
                    ])
                  : _vm._e(),
                data.item.out_of_office
                  ? _c("b-badge", { attrs: { variant: "info" } }, [
                      _vm._v(_vm._s(_vm.$t("OUT_OF_OFFICE"))),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(email)",
            fn: function (data) {
              return [_vm._v(_vm._s(data.item.email))]
            },
          },
          {
            key: "cell(auth_type)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.getAuthMethod(data.item)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(is_admin)",
            fn: function (data) {
              return [_vm._v(_vm._s(_vm.showAdmin(data.item.is_admin)))]
            },
          },
          {
            key: "cell(state)",
            fn: function (data) {
              return [
                data.item.type == "invite"
                  ? _c("b-form-checkbox", {
                      attrs: { name: "check-button", value: data.item.id },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(id)",
            fn: function (data) {
              return [
                _c("UserInfo", {
                  attrs: {
                    image: "/img/default-avatar.png",
                    primary: data.item.name,
                    secondary: data.item.email,
                  },
                }),
                data.item.title
                  ? _c("span", [_vm._v(_vm._s(data.item.title))])
                  : _vm._e(),
                data.item.department
                  ? _c("span", [
                      data.item.title ? _c("span", [_vm._v(", ")]) : _vm._e(),
                      _vm._v(_vm._s(data.item.department)),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center my-2" },
                  [_c("b-spinner", { staticClass: "align-middle" })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
            _c(
              "div",
              [
                _c(
                  "b-form",
                  { attrs: { inline: "" } },
                  [
                    _c("b-form-select", {
                      staticClass: "mr-2 medium-select",
                      attrs: { options: _vm.bulk_options },
                      model: {
                        value: _vm.bulk_action,
                        callback: function ($$v) {
                          _vm.bulk_action = $$v
                        },
                        expression: "bulk_action",
                      },
                    }),
                    !_vm.showRemove
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: !_vm.showBulk,
                              variant: "primary",
                              title: _vm.$t("SUBMIT"),
                            },
                            on: { click: _vm.sendAction },
                          },
                          [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                        )
                      : _vm._e(),
                    _vm.showRemove
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "danger" },
                            on: { click: _vm.sendAction },
                          },
                          [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalItems,
                  "per-page": _vm.limit,
                  align: "center",
                  pills: "",
                },
                on: { input: _vm.getItems },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "user-sidebar",
            "sidebar-class": "big_sidebar",
            right: "",
            backdrop: "",
            "no-slide": "",
            shadow: "",
          },
          model: {
            value: _vm.show_sidebar,
            callback: function ($$v) {
              _vm.show_sidebar = $$v
            },
            expression: "show_sidebar",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _vm.user_uuid != ""
                ? _c("UserPage", {
                    key: _vm.user_uuid,
                    attrs: { user_uuid: _vm.user_uuid },
                    on: {
                      removedUser: _vm.removedUser,
                      updatedUser: _vm.updatedUser,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "invite-sidebar",
            "sidebar-class": "big_sidebar",
            right: "",
            backdrop: "",
            "no-slide": "",
            shadow: "",
          },
          model: {
            value: _vm.show_invite_sidebar,
            callback: function ($$v) {
              _vm.show_invite_sidebar = $$v
            },
            expression: "show_invite_sidebar",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _vm.invite_id != 0
                ? _c("InvitePage", {
                    key: _vm.user_uuid,
                    attrs: {
                      "invite-id": _vm.invite_id,
                      organisation: _vm.organisation,
                    },
                    on: {
                      removedInvite: _vm.removedInvite,
                      updatedInvite: _vm.updatedInvite,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }