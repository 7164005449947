<template>
  <section class="mt-2">
    <b-form inline class="mt-2">
      <b-button @click="refreshList" class="no-border"
        ><i class="fal fa-sync"></i
      ></b-button>
      <AddInvite @invitedResult="refreshList" :organisation="organisation">
        <b-btn class="inlineBtn" variant="dark">
          {{ $t("ORGANISATION.ADD_USER") }}
        </b-btn>
      </AddInvite>
      <label class="sr-only" for="search-users">{{$t('SEARCH')}}</label>
      <b-form-input trim
        id="search-users"
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        @keydown.enter.prevent
        class="searchText hidden-mobile"
      ></b-form-input>
      <label class="sr-only" for="list-users-limit">{{$t('LIMIT')}}</label>
      <b-form-select id="list-users-limit" v-model="limit" class="limit hidden-mobile" @change="getItems">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>
      
      <div class="hidden-not-mobile w-100 mt-2">
        <b-form inline>
          <label class="sr-only" for="search-users-mobile">{{$t('SEARCH')}}</label>
          <b-form-input trim
            id="search-users-mobile"
            class="ml-2 i-100 searchText"
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
          ></b-form-input>
          <label class="sr-only" for="list-users-limit-mobile">{{$t('LIMIT')}}</label>
          <b-form-select id="list-users-limit-mobile" name="users_limit"  v-model="limit" class="limit">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="25">25</b-form-select-option>
            <b-form-select-option value="45">45</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-form>
      </div>
    </b-form>

    <div class="mt-2">
      <b-dropdown
        id="dropdown-1"
        class="filter-dropdown"
        size="sm"
        :text="filterTextStatus"
        :toggle-class="filterClassStatus"
      >
        <b-dropdown-form>
          <b-form-checkbox
            switch
            @change="getItems"
            size="sm"
            v-model="filter_status"
            name="check-button"
            value="0"
            >{{ $t("FILTER.STATUS_ACCOUNTS.0") }}</b-form-checkbox
          >
        </b-dropdown-form>
        <b-dropdown-form>
          <b-form-checkbox
            switch
            @change="getItems"
            size="sm"
            v-model="filter_status"
            name="check-button"
            value="1"
            >{{ $t("FILTER.STATUS_ACCOUNTS.1") }}</b-form-checkbox
          >
        </b-dropdown-form>
        <b-dropdown-divider
          v-if="filter_status.length != 0"
        ></b-dropdown-divider>
        <b-dropdown-item
          v-if="filter_status.length != 0"
          @click="clearFilterStatus"
          >{{ $t("FILTER.CLEAR_SELECTED") }}</b-dropdown-item
        >
      </b-dropdown>
      <b-button
        class="no-border"
        size="sm"
        v-if="showFilterClearAll"
        @click="clearFilterAll"
        >{{ $t("FILTER.CLEAR") }}</b-button
      >
    </div>

    <b-table
      :key="usersKey"
      small
      class="clickable mt-2"
      :busy="loading"
      bordered
      :items="items"
      hover
      :fields="fields"
      @row-clicked="clickedRow"
    >
      <template #head(state)>
        <b-form-checkbox
          v-if="items.length != 0"
          v-model="add_all"
          @change="addAll"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(name)="data">
        <b-img
          class="profileImage"
          src="/img/default-avatar.png"
          :alt="data.item.name"
          rounded="circle"
        ></b-img>
        {{ data.item.name }}
        <b-badge variant="info" v-if="data.item.type == 'invite'">{{
          $t("STATUS_INVITED")
        }}</b-badge>        
        <b-badge variant="info" v-if="data.item.out_of_office">{{
          $t("OUT_OF_OFFICE")
        }}</b-badge>        
      </template>

      <template v-slot:cell(email)="data">{{ data.item.email }}</template>

      <template v-slot:cell(auth_type)="data">
        {{ getAuthMethod(data.item) }}
      </template>

      <template v-slot:cell(is_admin)="data">{{
        showAdmin(data.item.is_admin)
      }}</template>

      <template v-slot:cell(state)="data">
        <b-form-checkbox
          v-if="data.item.type == 'invite'"
          v-model="selected"
          name="check-button"
          :value="data.item.id"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(id)="data">
        <UserInfo
          image="/img/default-avatar.png"
          :primary="data.item.name"
          :secondary="data.item.email"
        ></UserInfo>
        <span v-if="data.item.title">{{ data.item.title }}</span><span v-if="data.item.department"><span v-if="data.item.title">, </span>{{ data.item.department }}</span>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col cols="12" md="6">
        <div>
          <b-form inline>
            <b-form-select
              class="mr-2 medium-select"
              v-model="bulk_action"
              :options="bulk_options"
            ></b-form-select>
            <b-button
              :disabled="!showBulk"
              variant="primary"
              v-if="!showRemove"
              :title="$t('SUBMIT')"
              @click="sendAction"
              >{{ $t("SUBMIT") }}</b-button
            >
            <b-button variant="danger" v-if="showRemove" @click="sendAction">{{
              $t("REMOVE")
            }}</b-button></b-form
          >
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="limit"
          align="center"
          @input="getItems"
          pills
        >
        </b-pagination>
      </b-col>
    </b-row>

    <b-sidebar
      id="user-sidebar"
      sidebar-class="big_sidebar"
      right
      backdrop
      no-slide
      shadow
      v-model="show_sidebar"
    >
      <div class="px-3 py-2">
        <UserPage
          v-if="user_uuid != ''"
          @removedUser="removedUser"
          @updatedUser="updatedUser"
          :user_uuid="user_uuid"
          :key="user_uuid"
        >
        </UserPage>
      </div>
    </b-sidebar>

    <b-sidebar
      id="invite-sidebar"
      sidebar-class="big_sidebar"
      right
      backdrop
      no-slide
      shadow
      v-model="show_invite_sidebar"
    >
      <div class="px-3 py-2">
        <InvitePage
          v-if="invite_id != 0"
          @removedInvite="removedInvite"
          @updatedInvite="updatedInvite"
          :invite-id="invite_id"
          :organisation="organisation"
          :key="user_uuid"
        >
        </InvitePage>
      </div>
    </b-sidebar>
  </section>
</template>
<script>
import AddInvite from "@/components/Invite/Add";
import InvitePage from "@/components/Invite/Page";
import UserPage from "@/components/User/Page";
import UserInfo from "@/components/Layout/UserInfo";
export default {
  components: { UserPage, UserInfo, AddInvite, InvitePage },
  props: ["organisation"],
  data() {
    return {
      usersIndex: 0,
      searchText: "",
      limit: 10,
      filter_status: [],
      clickedIndex: 0,
      bulk_action: 0,
      bulk_options: [
        { value: 0, text: this.$t("RESEND") },
        { value: 1, text: this.$t("REMOVE") },
      ],
      loading: false,
      add_all: false,
      show_invite_sidebar: false,
      show_sidebar: false,
      currentSearch: this.searchText,
      currentLimit: this.limit,
      currentPage: 1,
      custom_authmethods: [],
      selected: [],
      items: [],
      totalItems: 0,
      user_uuid: "",
      invite_id: 0,
      state: 1,
      fields: [
        {
          key: "state",
          class: "w-30",
          thClass: "",
        },
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "",
          label: "",
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "title",
          label: this.$t("TITLE"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "department",
          label: this.$t("DEPARTMENT"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("EMAIL"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "is_admin",
          label: this.$t("ADMINLEVEL.TITLE"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "auth_type",
          label: this.$t("AUTH_METHODS.TITLE"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    refreshList() {
      this.usersIndex = this.usersIndex + 1;
      this.getItems();
    },
    clearFilterStatus() {
      this.filter_status = [];
      this.getItems();
    },
    clearFilterAll() {
      this.filter_status = [];
      this.getItems();
    },
    addAll: function () {
      if (this.add_all) {
        let filtered = this.items.filter(function (item) {
          return item.type == "invite";
        });
        this.selected = filtered.map((a) => a.id);
      } else {
        this.selected = [];
      }
    },
    async processMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        if (method.auth_type == "custom") {
          this.custom_authmethods.push({
            value: method.auth_method,
            text: method.custom_name,
          });
        }
      }
    },
    async getMethods() {
      let self = this;
      self.loading = true;
      await this.$http
        .get(this.user.hostname + "/invite/methods/list")
        .then(function (result) {
          self.methods = result.data;
          self.loading = false;
          self.processMethods();
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updatedUser: function () {
      this.user_uuid = "";
      this.show_sidebar = false;
      this.getItems();
    },
    updatedInvite: function () {
      this.invite_id = 0;
      this.show_invite_sidebar = false;
      this.getItems();
    },
    removedUser() {
      this.user_uuid = "";
      this.show_sidebar = false;
      this.getItems();
    },
    removedInvite() {
      this.invite_id = 0;
      this.show_invite_sidebar = false;
      this.getItems();
    },
    showState: function (state) {
      if (state === 1) {
        return this.$t("ACTIVE");
      }
      return this.$t("INACTIVE");
    },
    showAdmin: function (is_admin) {
      if(is_admin == 0) return "";
      return this.$t("ADMINLEVEL." + is_admin);
    },
    getAuthMethod: function (item) {
      if (item.auth_type != "custom") {
        if (item.auth_method != "") {
          return this.$t("AUTH_METHODS." + item.auth_method);
        } else {
          return this.$t("AUTH_METHODS." + item.auth_type);
        }
      } else {
        let filtered = this.custom_authmethods.filter(
          (c) => c.value == item.auth_method
        );
        if (filtered[0] != undefined) {
          if (filtered[0].text != undefined) {
            return filtered[0].text;
          } else {
            return "";
          }
        }
      }
    },
    getItems: function () {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/organisation/list/all/users", {
          page: this.currentPage,
          organisation_id: this.organisation.id,
          state: this.state,
          limit: parseInt(this.limit),
          search_text: this.searchText,
          filter_status: this.filter_status,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    clickedRow: function (record) {
      if (record.type == "account") {
        this.user_uuid = record.user_uuid;
        this.show_sidebar = true;
      }
      if (record.type == "invite") {
        this.invite_id = record.id;
        this.show_invite_sidebar = true;
      }
    },
    sendAction: function () {
      let self = this;
      if (this.selected.length == 0) {
        return false;
      }
      if (this.bulk_action == 0) {
        this.$http
          .post(this.user.hostname + "/invite/resend", {
            invites: this.selected,
          })
          .then(function () {
            self.selected = [];
            self.$noty.info(self.$t("SENT"));
          })
          .catch(() => {
          });
      }
      if (this.bulk_action == 1) {
        this.$bvModal
          .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("YES"),
            cancelTitle: this.$t("NO"),
            bodyClass: "messagebox",
            footerClass: "p-2  text-center messagebox",
            hideHeaderClose: false,
            centered: true,
          })
          .then(function (value) {
            if (value) {
              self.$http
                .post(self.user.hostname + "/invite/remove", {
                  invites: self.selected,
                })
                .then(function () {
                  self.selected = [];
                  self.add_all = false;
                  self.$noty.info(self.$t("REMOVED"));
                  self.getItems();
                })
                .catch(() => {
                });
            }
          })
          .catch(function () {});
      }
    },
  },
  computed: {
    showFilterClearAll() {
      if (this.filter_status.length != 0) {
        return true;
      }
      return false;
    },
    filterClassStatus() {
      if (this.filter_status.length != 0) {
        return ["filter-selected", "no-border"];
      } else {
        return ["no-border"];
      }
    },
    filterTextStatus() {
      if (this.filter_status.length != 0) {
        if (this.filter_status.length > 1) {
          return (
            this.$t("FILTER.STATUS_ACCOUNTS.TITLE") +
            ": " +
            this.$t(
              "FILTER.STATUS_ACCOUNTS." + this.filter_status.slice().sort()[0]
            ) +
            " +(" +
            (this.filter_status.length - 1) +
            ")"
          );
        } else {
          return (
            this.$t("FILTER.STATUS_ACCOUNTS.TITLE") +
            ": " +
            this.$t(
              "FILTER.STATUS_ACCOUNTS." + this.filter_status.slice().sort()[0]
            )
          );
        }
      } else {
        return this.$t("FILTER.STATUS_ACCOUNTS.TITLE");
      }
    },
    showBulk: function () {
      return this.selected.length != 0;
    },
    showRemove: function () {
      if (this.selected.length != 0) {
        if (this.bulk_action == 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    usersKey: function () {
      return "userlist_" + this.usersIndex;
    },
  },
  watch: {
    filter_status: function (new_array) {
      localStorage.setItem("organisation_users", JSON.stringify(new_array));
    },
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getItems();
      }
      if (this.searchText.length >= 2) {
        this.getItems();
      }
    },
  },
  mounted: function () {
    if (localStorage.getItem("organisation_users") !== null) {
      this.filter_status = JSON.parse(
        localStorage.getItem("organisation_users")
      );
    }
    this.getMethods();
    this.getItems();
  },
};
</script>
<style></style>
